@keyframes line-one {
    0% {
      stroke-width: 2;
      stroke-dashoffset: 3000;
    }
    50% {
      stroke-width: 6;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-width: 2;
      stroke-dashoffset: 3000;
    }
  }

.al{

    &__wrapper{
        position: absolute;
        width: 60%;
        // height: calc(var(--app-height) - 20px - 13vw);
        height: calc(var(--app-height) - 40px);

        // aspect-ratio: 3/5;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__animated-line-one,
    &__animated-line-two{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        stroke-width: 2;
    }

    &__animated-line-one{
        animation: line-one 2s linear infinite;
        stroke-dasharray: 3000;
        animation-delay: 1.6s;
    }

    &__animated-line-two{
        animation: line-one 2s ease-in-out infinite;
        stroke-dasharray: 3000;
    }

}

@include mobile{

  .al{

    &__wrapper{
        position: absolute;
        width: calc(100% - 60px);
        // height: calc(var(--app-height) - 20px - 13vw);
        height: calc(var(--app-height) - 60px);

        // aspect-ratio: 3/5;
        top: 30px;
        left: 30px;
        transform: none;
    }
  }
}