.nav{

    &__wrapper{
        position: fixed;
        width: calc(100% - 40px);
        top: 0;
        left: 0;
        margin: 20px;
        z-index: 100;
        color: var(--charli-green);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-shadow: 0px 0px 2px var(--charli-green);
    }

    &__logo-wrapper{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 80px;
    }

}

@include mobile {

    .nav{

    //     &__wrapper{
    //         width: calc(100% - 40px);
    //         padding: 0 20px;
    //         &.top{
    //             top: 20px;
    //         }
    
    //         &.bottom{
    //             top: calc(100% - 54px);
    //         }
    //     }


    // &__tablet,
    // &__published{
    //     display: block;
    // }

    }

}