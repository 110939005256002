.yt {
  &__wrapper {
    padding: 50px 0;
    overflow: hidden;

    .embed-container {
      position: relative;
      padding: 50px 50px 56.25% 50px;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }
    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 100px);
      height: calc(100% - 100px);
      -webkit-box-shadow: 0px 0px 15px 1px var(--charli-green);
      -moz-box-shadow: 0px 0px 15px 1px var(--charli-green);
      box-shadow: 0px 0px 15px 1px var(--charli-green);
      border-radius: 5px;
    }
  }
}

@include mobile{

    .yt {
        &__wrapper {
          padding: 30px 0;
          .embed-container {
            padding: 30px 30px 56.25% 30px;
          }
          .embed-container iframe,
          .embed-container object,
          .embed-container embed {
            width: calc(100% - 60px);
            height: calc(100% - 60px);
          }
        }
      }
}
