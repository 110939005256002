.pull-quote {

  &__wrapper {
    width: 100%;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__card-wrapper {
    margin: 0 auto;
    width: 40%;
    min-height: 40vw;
    height: auto;
    // aspect-ratio: 1/1;
    z-index: 2;
    // overflow: hidden;
    // position: absolute;
    top: 50%;
    left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform-style: preserve-3d;
    color: #FFF;
    &.flip{
    @include hover{
      .pull-quote__card-front{
        transform: rotateY( 180deg ) ;
      }
      .pull-quote__card-back{
        transform: rotateY( 0deg ) ;
      }
      
    }
    }

  }

  &__card-front,
  &__card-back{
    // position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backface-visibility: hidden; 
    transition: transform 0.4s ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__card-front{
    min-height: 40vw;
  }

  &__card-front h3{
    text-align: justify;
    text-justify: inter-word;
    padding: 30px;
    filter: blur(0.5px);
    font-size: 5vw;
  }

  &__credit{
    position: absolute;
    top: 100%;
    right: 20px;
    font-size: 40px;
    line-height: 40px;
    // color: #000;
  }

  &__card-back{
    transform: rotateY( 180deg );
  }

  &__cursor{
    animation: pulse 0.5s linear infinite;
    &.disabled{
        display: none;
    }
  }

  &__background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      bottom: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
  }

  &__background-image {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__background-video-wrapper {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    overflow: hidden;
  }

  &__background-video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@include mobile {


  .pull-quote {

    &__card-wrapper {
      width: calc(100% - 80px);
      // aspect-ratio: 1/1;
      min-height: calc(100vw - 80px);
      position: static;
      margin: 40px;
      transform: none;
      height: auto;
      &.flip{
      @include hover{
        .pull-quote__card-front{
          transform: rotateY( 0deg ) ;
        }
        .pull-quote__card-back{
          transform: rotateY( 180deg ) ;
        }
        
      }
      }
    }

    &__card-front,
    &__card-back{
      position: relative;
      min-height: calc(100vw - 80px);
    }

    &__card-back{
      display: none;
    }

    &__card-front h3{
      padding: 15px;
      // filter: blur(1px);
      font-size: 50px;
      line-height: 55px;
    }

    &__credit{
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 20px;
      line-height: 20px;
    }

  }

}