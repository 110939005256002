.texts {
  &__wrapper {
    width: 100%;
    min-height: var(--app-height);
    position: relative;
  }

  &__messages-wrapper {
    margin: 150px auto;
    width: 40%;
    position: relative;
    z-index: 2;
  }

  &__message-wrapper {
    width: 100%;
    display: block;
    transition: all 0.2s ease-in-out;
    position: relative;
    top: 50px;
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
    &.active {
      top: 0;
      transform: scale(1);
      opacity: 1;
      pointer-events: all;
    }
    margin-bottom: 30px;
    // &.left{
    //   margin-bottom: 40px;
    // }
    // &.right{
    //   margin-bottom: 15px;
    // }
  }
  &__message-inner {
    max-width: calc(90% - 50px);
    display: inline-block;
    padding: 15px;
    background-color: var(--charli-green);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 0px var(--charli-green);
    -moz-box-shadow: 0px 0px 20px 0px var(--charli-green);
    box-shadow: 0px 0px 20px 0px var(--charli-green);
    color: #000;
    position: relative;
    cursor: pointer;
    pointer-events: none;
    &.clickable{
      pointer-events: all;
    }
    &.right {
      margin-left: calc(20% + 20px);
      background-color: #000;
      border: 1px solid var(--charli-green);
      color: var(--charli-green);
    }
  }

  &__sender{
    position: absolute;
    bottom: calc(100% + 5px);
    left: 15px;
    color: var(--charli-green);
  }
  &__image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  &__emoji {
    position: absolute;
    top: calc(100% - 15px);
    padding: 10px;
    border-radius: 50%;
    border-top: 1px solid #000;
    background-color: var(--charli-green);
    -webkit-box-shadow: 0px 0px 20px 0px var(--charli-green);
    -moz-box-shadow: 0px 0px 20px 0px var(--charli-green);
    box-shadow: 0px 0px 20px 0px var(--charli-green);
    left: 15px;
    pointer-events: none;
    z-index: 2;
    &.active {
      pointer-events: all;
    }
    &.right {
      left: auto;
      right: 15px;
    }
  }

  &__image-button {
    pointer-events: none;
    &.active {
      pointer-events: all;
    }
  }
  &__focus-wrapper {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(5px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__focus-image {
    display: block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__react-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: var(--app-height);
    overflow: hidden;
    z-index: 2000;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.active {
      opacity: 1;
    }
  }

  &__react-emoji {
    font-size: 120px;
    position: absolute;
    top: 100%;
    &.active {
      top: 0;
    }
  }

  &__background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      bottom: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
  }

  &__background-image {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 60%;
  }

  &__background-video-wrapper {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    opacity: 60%;
    overflow: hidden;
  }

  &__background-video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include mobile{

  .texts {

    &__messages-wrapper {
      width: calc(100% - 40px);
    }

    &__message-inner {
      max-width: 100%;
      &.right {
        margin-left: calc(10% + 20px);
      }
    }

    &__react-emoji {
      font-size: 80px;
    }
  }

}