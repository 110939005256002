@keyframes bounce {
    0% {
      top: -20px;
    }
    100% {
      top: 20px;
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;

    }
    50%{
      opacity: 0;
    }
    100% {
      opacity: 1;

    }
  }
  

  // @keyframes line-one {
  //   0% {
  //     stroke-width: 2;
  //     stroke-dashoffset: 1000;
  //   }
  //   50% {
  //     stroke-width: 6;
  //     stroke-dashoffset: 0;
  //   }
  //   100% {
  //     stroke-width: 2;
  //     stroke-dashoffset: 1000;
  //   }
  // }