.stand-first {
  &__wrapper {
    width: 100%;
    min-height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__text-wrapper {
    margin: 150px auto;
    width: 45%;
    aspect-ratio: 1/1;
    text-align: justify;
    text-justify: inter-word;
    filter: blur(1px);
  }

  &__cursor{
    animation: pulse 0.5s linear infinite;
    &.disabled{
        display: none;
    }
  }
}

@include mobile {
  .stand-first {
    &__wrapper {
      margin: 50px 10px;
    }
  }
}
