.left-right {
  &__wrapper {
    width: 100%;
    min-height: 200vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &.text-only {
      min-height: auto;
    }
  }

  &__half {
    width: calc(50% - 60px);
    margin: 0 10px 0 30px;
    position: relative;
    z-index: 2;
    &.right {
      margin: 0 30px 0 10px;
    }
  }

  &__text-wrapper {
    position: sticky;
    top: 80px;
    &.text-only {
      position: static;
    }
    p {
      margin-bottom: 30px;
    }
    a{
      text-decoration: underline;
    }
    &.small-text {
      p {
        max-width: 600px;
        margin: 0 auto 30px auto;
      }
    }
  }

  &__image-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: calc(100vh - 150px);
    margin: 0 auto;
    border-radius: 10px;
    // -webkit-box-shadow: 0px 0px 20px 0px var(--charli-green);
    // -moz-box-shadow: 0px 0px 20px 0px var(--charli-green);
    // box-shadow: 0px 0px 20px 0px var(--charli-green);
  }

  &__background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      bottom: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
  }

  &__background-image {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 60%;
  }

  &__background-video-wrapper {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    opacity: 60%;
    overflow: hidden;
  }

  &__background-video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include mobile {
  .left-right {
    &__wrapper {
      min-height: auto;
      display: block;
      margin: 30px 0;
    }

    &__half {
      width: calc(100% - 60px);
      margin: 30px;
      position: relative;
      z-index: 2;
      &.right {
        margin: 30px;
      }
    }

    &__text-wrapper {
      position: static;
      p {
        margin-bottom: 30px;
      }
      &.small-text {
        p {
          max-width: none;
          margin: 0 0 30px 0;
        }
      }
    }
  }
}
