.intro {
  &__container {
    width: 100%;
    // height: var(--app-height);
    margin-bottom: 50px;
    position: relative;
    pointer-events: none;
    &.active{
      pointer-events: all;
    }
  }

  // &__background{
  //   width: 100%;
  //   height: var(--app-height);
  //   background-size: cover;
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   position: sticky;
  //   top: 0;
  // }

  &__fade-wrapper{
    width: 100%;
    height: calc(var(--app-height) * 3.5);
    position: relative;

  }

  &__fade-sticky{
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__fade-top{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.first{
      z-index: 2;
      background-color: var(--charli-green);
    }
  }

  // &__wrapper {
  //   width: 100%;
  //   height: var(--app-height);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   overflow: hidden;
  //   opacity: 1;
  //   transition: all 0.8s ease-in-out;
  //   &.disabled {
  //     opacity: 0;
  //     pointer-events: none;
  //   }

  //   &.first{
  //     background-color: var(--charli-green);
  //     z-index: 101;
  //   }

  //   pointer-events: none;
  // }

  &__text-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: 20px 0;
    color: #000;
    text-shadow: 0px 0px 4px #000;
    &.green {
      color: var(--charli-green);
      text-shadow: 0px 0px 4px var(--charli-green);
    }
  }

  &__large-text {
    font-size: 9vw;
    line-height: 6.5vw;
  }

  &__small-text {
    font-size: 6vw;
    line-height: 5vw;
  }

  &__nav-wrapper {
    position: absolute;
    width: calc(100% - 40px);
    top: 0;
    left: 0;
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000;
    text-shadow: 0px 0px 2px #000;
    z-index: 10000;
  }

  &__nav-logo-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 80px;
  }

  &__image-container{
    width: 100%;
    height: var(--app-height);
    overflow: hidden;
    position: sticky;
    top: 0;
  }
  &__image-wrapper {
    width: 100%;
    height: var(--app-height);
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@include mobile {

    .intro{
      &__large-text {
        font-size: 19vw;
        line-height: 19vw;
      }
    
      &__small-text {
        font-size: 10vw;
        line-height: 10vw;
      }
  }
}

// @include mobile {

//   .intro{

//     &__logo-wrapper{
//       display: block;
//       position: absolute;
//       top: 20px;
//       width: 30vw;
//       left: 35vw;
//       height: auto;
//       z-index: 10;
//     }
//     &__logo{
//       width: 100%;
//     }

//     &__line-wrapper {
//       position: absolute;
//       width: 100vw;
//       height: 0;
//       aspect-ratio: inherit;
//       &.active {
//         height: 100vh;
//       }
//       pointer-events: none;
//     }

//     &__line {
//       &::before {
//         height: 70%;
//         width: calc(50% - 1px);
//         top: 15%;
//         border-right: 2px solid var(--ra-red);
//       }
//     }
//   }
// }
