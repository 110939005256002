.cover {
  &__wrapper {
    width: 100vw;
    height: var(--app-height);
    position: relative;
    overflow: hidden;
  }

  &__video,
  &__image {
    height: calc(100% - 200px);
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10vh;
      bottom: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      z-index: 2;
    }
  }

  &__background-image {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 60%;
  }

  &__background-video-wrapper {
    width: 100%;
    height: var(--app-height);
    position: sticky;
    top: 0;
    opacity: 60%;
    overflow: hidden;
  }

  &__background-video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include mobile {
  .cover {
    &__video,
    &__image {
      height: auto;
      width: calc(100% - 80px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
