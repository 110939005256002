

*::selection {
  background: var(--charli-green);
  color: #000;
}
*::-moz-selection {
  background: var(--charli-green);
  color: #000;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
*::-webkit-scrollbar-track {
  background-color: #000;
}

*::-webkit-scrollbar-thumb {
  background: var(--charli-green);
  border: none;
}


html,
body {
  width: 100%;
  height: 100%;
  background-color: #000;
}


.global{

  &__blocker{
    width: 100vw;
    height: var(--app-height);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200000;
    backdrop-filter: blur(15px);
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.4s ease-in-out;
    &.disabled{
      opacity: 0;
      pointer-events: none;
    }
  }

  &__start-button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 30px;
    font-family: "Alternate Gothic Pro No One", sans-serif;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    text-transform: uppercase;
    background-color: var(--charli-green);
    border: 1px solid #000;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 15px 1px #000;
    -moz-box-shadow: 0px 0px 15px 1px #000;
    box-shadow: 0px 0px 15px 1px #000;
    color: #000;
  }
}
// #CharliArticle{
//   pointer-events: none;
//   &.active{
//     pointer-events: all;
//   }
// }