.full-bleed{

    &__wrapper{
        width: 100%;
        height: var(--app-height);
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 10vh;
            top: 0;
            left: 0;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            z-index: 2;
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 10vh;
            bottom: 0;
            left: 0;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            z-index: 2;
        }
      }

      &__video{
        display: block;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

}