.read-time{

    &__wrapper{
        width: 100%;
        height: 10px;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 200;
        pointer-events: none;
    }

    &__progress{
        position: absolute;
        height: 100%;
        background-color: var(--charli-green);
        left: 0;
    }

    &__number{
        position: absolute;
        left: calc(100% + 10px);
        color: var(--charli-green);
        font-family: "Helvetica-Bold", sans-serif;
        text-wrap: nowrap;
        font-size: 8px;
        line-height: 8px;
    }
}