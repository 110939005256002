@font-face {
  font-family: "Alternate Gothic Pro No One";
  src: url("../assets/fonts/AlternateGothicPro-NoOne.eot");
  src: url("../assets/fonts/AlternateGothicPro-NoOne.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/AlternateGothicPro-NoOne.woff2") format("woff2"),
    url("../assets/fonts/AlternateGothicPro-NoOne.woff") format("woff"),
    url("../assets/fonts/AlternateGothicPro-NoOne.ttf") format("truetype"),
    url("../assets/fonts/AlternateGothicPro-NoOne.svg#AlternateGothicPro-NoOne")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica.eot');
  src: url('../assets/fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Helvetica.woff2') format('woff2'),
      url('../assets/fonts/Helvetica.woff') format('woff'),
      url('../assets/fonts/Helvetica.ttf') format('truetype'),
      url('../assets/fonts/Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('../assets/fonts/Helvetica-Bold.eot');
  src: url('../assets/fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Helvetica-Bold.woff2') format('woff2'),
      url('../assets/fonts/Helvetica-Bold.woff') format('woff'),
      url('../assets/fonts/Helvetica-Bold.ttf') format('truetype'),
      url('../assets/fonts/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica-Light';
  src: url('../assets/fonts/Helvetica-Light.eot');
  src: url('../assets/fonts/Helvetica-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Helvetica-Light.woff2') format('woff2'),
      url('../assets/fonts/Helvetica-Light.woff') format('woff'),
      url('../assets/fonts/Helvetica-Light.ttf') format('truetype'),
      url('../assets/fonts/Helvetica-Light.svg#Helvetica-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


html,
body {
  font-family: "Helvetica", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFF;
}


h1 {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-align: center;
  font-size: 15vw;
  line-height: 15vw;
  text-transform: uppercase;
}

h2 {
  text-align: center;
  font-size: 4vw;
  line-height: 3.8vw;
  text-transform: uppercase;
  letter-spacing: -0.2vw;
}

h3 {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-align: center;
  font-size: 6vw;
  line-height: 4.5vw;
  text-transform: uppercase;
}

h4 {
  font-size: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  @include hover {
    text-decoration: none;
  }
}

em {
  font-style: italic;
}

.strong {
  font-family: "Helvetica-Bold", sans-serif;
}

.alt-font {
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-transform: uppercase;
}

.large-text{
  font-size: 30px;
  line-height: 32px;
  font-family: "Helvetica-Bold", sans-serif;
}

.left-right__text-wrapper.pull-quote{
  font-family: "Alternate Gothic Pro No One", sans-serif;
  text-transform: uppercase;
  font-size: 4vw;
  line-height: 3.8vw;
  text-align: justify;
  text-justify: inter-word;
}

@include mobile {
  
  html,
  body {
    font-size: 16px;
    line-height: 18px;
  }

  h1 {
    font-size: 23vw;
    line-height: 23vw;
  }

  h2 {
    font-size: 7vw;
    line-height: 6vw;
  }

  h3 {
    font-size: 12vw;
    line-height: 10vw;
  }

  .large-text{
    font-size: 20px;
    line-height: 22px;
    font-family: "Helvetica-Bold", sans-serif;
  }
}
